import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CommonModule, NgIf, NgTemplateOutlet } from '@angular/common';
import { SvgIconService } from 'src/app/service/svg-icon.service';
import { PlatformService } from 'src/app/service/platform.service';

@Component({
  selector: 'app-icons',
  standalone: true,
  imports: [NgIf, CommonModule],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsComponent implements OnChanges, OnInit {
  private readonly svgIconService: SvgIconService = inject(SvgIconService);
  private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  // Config
  @Input({ required: true }) name!: string;
  @Input() width: number | string = 40;
  @Input() height: number | string = 40;
  @Input() fillColor?: string;
  @Input() stroke?: string;
  @Input() rotate?: number | string = 0;

  @HostBinding('style.rotate') styleRotate = '0deg';

  public iconUrl: any;
  private isBrowser = false;
  constructor(private platform: PlatformService) {
    this.isBrowser = this.platform.isBrowser;
  }

  ngOnInit(): void { }

  get useFillColor(): boolean {
    return this.name !== 'logo_static';
  }

  private loadIcon(name: string): void {
    this.svgIconService.getIcon(name).then((iconSvg) => {
      if (iconSvg) {
        this.iconUrl = iconSvg as string;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rotate']) {
      this.styleRotate = this.rotate + 'deg';
    }
    if (changes['name']) {
      this.loadIcon(this.name);
    }
  }
}
