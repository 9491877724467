import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private viewportWidth: number = 0;
  public tabletScreen: number = 1024;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ){
    this.setViewportWidth();
  }


  private setViewportWidth(): number {
    if (isPlatformBrowser(this.platformId)) {
      this.viewportWidth =  window.innerWidth;
    }
    return 0;
  }

  get getViewportWidth(): number {
    return this.viewportWidth;
  }

  get isBrowser(): boolean {
    return  isPlatformBrowser(this.platformId);
  }
  get tabletScreenSize(): number {
    return this.tabletScreen;
  }
}
